import { useState } from 'react'
import { Link } from 'react-router-dom';
import AnimatedLetters from '../AnimatedLetters';
import {
    faWordpress,
    faCss3,
    faShopify,
    faHtml5,
    faFigma,
    faReact,
    faAppStore,
    faAndroid,
} from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './index.scss';




const Home = () => {
    const [letterClass] = useState('text-animate')
    const nameArray = ['','K', 'a', 'l', 'e',]
    const jobArray = ['F','.','E',' ', 'D', 'e', 'v', 'e', 'l', 'o', 'p', 'e', 'r', '.']

    return (
        <div className="container home-page">
            <div className="text-zone">
                <h1>
                    <br />
                    <span className={`${letterClass} _13`}>I</span>
                    <span className={`${letterClass} _14`}>'m</span>
                    <AnimatedLetters letterClass={letterClass}
                        strArray={nameArray}
                        idx={15} />
                    <br />
                    <AnimatedLetters letterClass={letterClass}
                        strArray={jobArray}
                        idx={22} />
                </h1>
                <h3> Australian based</h3>
                <h2> Front End Dev / React / React Native / Shopify etc</h2>
                <div className="button-container">

                <a href="https://calendly.com/kaleahanby/30min" target="_blank" rel="noopener noreferrer" className='flat-button'>CONTACT ME</a>
                </div>

            </div>


            <div className="stage-cube-cont">
                <div className="cubespinner">
                    <div className="face1">
                        <FontAwesomeIcon icon={faFigma} color="#DD0031" />
                    </div>
                    <div className="face2">
                        <FontAwesomeIcon icon={faHtml5} color="#F06529" />
                    </div>
                    <div className="face3">
                        <FontAwesomeIcon icon={faCss3} color="#28A4D9" />
                    </div>
                    <div className="face4">
                        <FontAwesomeIcon icon={faReact} color="#5ED4F4" />
                    </div>
                    <div className="face5">
                        <FontAwesomeIcon icon={faAppStore} color="#EFD81D" />
                    </div>
                    <div className="face6">
                        <FontAwesomeIcon icon={faAndroid} color="#EC4D28" />
                    </div>
                </div>
            </div>
        </div>

    )

}

export default Home
