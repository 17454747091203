import React, { useState } from 'react';
import ProjectCard from '../ProjectCard';
import './index.scss';

const About = () => {

    const [projects, setProjects] = useState([
        {
            imgSrc: 'https://tempyjobs.com.au/wp-content/uploads/2023/07/19.png',
            title: 'Tempy',
            description: 'Connecting Temps & Employers Effortlessly Tempy connects you with a wide range of temporary positions in your area. Browse, apply, and start earning today!.',
            link: 'https://tempyjobs.com.au/'
        },
        {
          imgSrc: 'https://spritely.co/wp-content/uploads/2023/09/Medi-tile23.png',
          title: 'Emedmx',
          description: 'The Emergency Medication Mx system is a cloud-based application designed for individuals at risk of severe medical emergencies It ensures that life-saving medications are available and up-to-date in case of an emergency. The system tracks medication devices and their expiration dates, offering options for individuals, families, and businesses. ',
          link: 'https://emedmx.com/'
        },
        {
            imgSrc: 'https://spritely.co/wp-content/uploads/2023/09/T2Yhero2.png',
            title: 'Trailer2You',
            description: 'The team at Trailer 2 You engaged Spritely to build a revolutionary trailer hire system. Customers are able to hire trailer of their choice and get it delivered directly. Users can select view trailer details, Select the time and date of delivery and track the drivers when they are on the way. Trailers delivered with the T2Y franchise receive notifications for new hire order, with a full breakdown of the trailer order, address and time of delivery.',
            link: 'https://trailer2you.com.au/'
          },  
        {
            imgSrc: 'https://spritely.co/wp-content/uploads/2023/01/GTL-1.png',
            title: 'Guide to Light',
            description: 'Guide to Light is an app focused on enhancing Intuitive Psychic Development, Mindfulness, and Meditation, encouraging users to integrate personal growth into their daily routines. The challenge entailed creating an app that guides users through these practices, ensuring engagement, user-friendliness, and compatibility across iOS and Android platforms.',
            link: 'https://guidetolight.com.au/'
          },
          {
            imgSrc: 'https://spritely.co/wp-content/uploads/2022/03/Cheer-Collec.png',
            title: 'Cheer Collective',
            description: 'The Cheer Collective is an innovative organisation that offers a platform for users to establish, monitor, and achieve their goals within a supportive community of like-minded individuals. ',
            link: 'https://www.thecheercollective.com/'
          },
          {
            imgSrc: 'https://lively.digital/wp-content/uploads/2024/02/Group-5.png',
            title: 'Training Tailor Made',
            description: 'Custom Wordpress template development and design',
            link: 'https://trainingtailormade.com.au/'
          },
          {
            imgSrc: 'https://spritely.co/wp-content/uploads/2020/07/Actv-Strength.jpg',
            title: 'ACTV',
            description: 'ACTV Online provides you the resources required to get your desired results in strength, stamina, and body composition. Choose which of ACTV’s three progressive programs suit your circumstances and then you’re ready to ‘Get ACTV anytime, anywhere.',
            link: 'https://actvonline.com.au/'
          },
          {
            imgSrc: 'https://lively.digital/wp-content/uploads/2024/02/Group-4.png',
            title: 'The Gentleman Routine',
            description: 'Custom Shopify template development',
            link: 'https://thegentlemanroutine.com/'
          },
          {
            imgSrc: 'https://lively.digital/wp-content/uploads/2022/06/thraemockup.png',
            title: 'Thrae',
            description: 'Custom Shopify template development and design',
            link: 'https://thrae.com.au/'
          },
          {
            imgSrc: 'https://lively.digital/wp-content/uploads/2024/02/Group-2.png',
            title: 'Tamborine Springs',
            description: 'Custom Shopify template development',
            link: 'https://tamborinesprings.com.au/'
          },
        ]);

    return (
        <div className="about horizontal-scroll">
            <h1>My Work</h1>
            <div className="project-list">
                {projects.map(project => (
                    <ProjectCard key={project.title} project={project} />
                ))}
            </div>
        </div>
    );
};

export default About;