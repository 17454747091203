import React, { useState } from 'react'
import './index.scss';

const Contact = () => {

      return (
        <div className="contact">
        <div className="form">
          <div className="contact-us">Contact Us</div>
          <div className="frame-parent">
            <div className="frame-group">
              <div className="name-parent">
                <div className="name">Name</div>
                <input
                  className="email"
                  type="text"
                  placeholder="Name"
                  maxLength
                  minLength
                />
              </div>
              <div className="name-parent">
                <div className="name">Email Address</div>
                <input
                  className="email1"
                  type="text"
                  placeholder="Enter your email address"
                  maxLength
                  minLength
                />
              </div>
              <div className="name-parent">
                <div className="name">
                  <span>Phone Number</span>
                  <span className="optional"> (Optional)</span>
                </div>
                <input className="email1" type="text" maxLength minLength />
              </div>
            </div>
            <div className="message-parent">
              <div className="name">Message</div>
              <textarea className="email3" placeholder="Enter your message" />
            </div>
          </div>
          <button className="search-flights-button">
            <div className="button">Send message</div>
          </button>
        </div>
        </div>
      );
    };
    

export default Contact;
