import React from 'react';
import './index.scss';
import { Link } from 'react-router-dom';
import { faProductHunt } from '@fortawesome/free-brands-svg-icons';


const ProjectCard = ({ project }) => {
    return (
      <div className="project-card">
        <div className='project-image'>
          <img src={project.imgSrc} alt={project.title} />
        </div>
        <div className="project-info">
          <h3>{project.title}</h3>
          <p>{project.description}</p>
          <a href={project.link} target='_blank' rel='noopener noreferrer' className='flat-button'>See Project</a>
        </div>
      </div>
    );
  };
  

export default ProjectCard;