import { Link, NavLink } from 'react-router-dom'
import './index.scss'
import LogoS from '../../assets/images/pillars.png'
import LogoSubtitle from '../../assets/images/logo_sub.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faHome, faLaptop } from '@fortawesome/free-solid-svg-icons'
import { faGithub } from '@fortawesome/free-brands-svg-icons'
const Sidebar = () => (
    <div className='nav-bar'>
        <nav>
            <NavLink exact="true"  to="/">
                <FontAwesomeIcon icon={faHome} color="#4d4d4e" />
                <h2>Home</h2>
            </NavLink>
            <NavLink exact="true" className="about-link" to="/about">
                <FontAwesomeIcon icon={faLaptop} color="#4d4d4e" />
                <h2>Portfolio</h2>
            </NavLink>
            <a href="https://calendly.com/kaleahanby/30min" target="_blank" rel="noopener noreferrer" className="contact-link">
                <FontAwesomeIcon icon={faEnvelope} color="#4d4d4e" />
                <h2>Contact</h2>
            </a>
        </nav>
    </div>
)

export default Sidebar